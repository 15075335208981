@import "../../common";


.factsCheckRow{
  box-shadow: 0px 4px 16px $primary-color;
  border-radius: 50px;
  position: relative;
}

#FactsCheck{
  p {
    line-height: 35px;
    span{
      color: $primary-color;
    }
  }
}
.rightBorderGold{
  border-right: solid 2px $primary-color;
}
